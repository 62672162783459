<template>
<form @submit.prevent="getLogin">
    <fieldset>
        <label class="block clearfix">
            <span class="block input-icon input-icon-right">
                <input type="text" class="form-control" name="usuario" placeholder="CPF ou E-mail" v-model="params.email" />
                <i class="ace-icon fa fa-user"></i>
            </span>
        </label>

        <label class="block clearfix">
            <span class="block input-icon input-icon-right">
                <input type="password" class="form-control" placeholder="Senha" v-model="params.password" />
                <i class="ace-icon fa fa-lock"></i>
            </span>
        </label>

        <div class="space"></div>

        <div class="clearfix">
            <button type="submit" class="width-35 pull-right btn btn-sm btn-primary" :disabled="disabled">
                <i class="ace-icon fa fa-key"></i>
                <span class="bigger-110">Acessar</span>
            </button>
        </div>

        <div class="space-4"></div>
    </fieldset>
</form>
</template>

<script>
import moment from 'moment'
import jwt from 'jwt-simple'
import swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default {
  name: 'Acessar',
  data () {
    return {
      params: {
        email: '',
        password: ''
      },
      errors: [],
      disabled: false
    }
  },
  methods: {
    getLogin () {
      this.disabled = true
      this.$axios.post('/login', {
        email: this.params.email,
        password: this.params.password
      }).then(resp => {
        this.disabled = false
        if (resp.data.token) {
          swal.fire({
            title: 'Sucesso!',
            text: 'Aguarde... Redirecionando...',
            type: 'success',
            timer: 2000
          })
          setTimeout(() => {
            localStorage.setItem('user', jwt.encode(JSON.stringify(resp.data), 'vox'))
            localStorage.setItem('token', resp.data.token)
            localStorage.setItem('_filtroVendas', JSON.stringify({
              cod: null,
              cliente: null,
              datavenda: null,
              status: null,
              usuario: null,
              instalador: null,
              datatipo: 'datacad',
              tx: null,
              categoria_id: null,
              dataini: moment().startOf('day').startOf('month').format('YYYY-MM-DD'),
              datafim: moment().format('YYYY-MM-DD')
            }))
            this.$router.push('/')
          }, 2000)
        } else if (resp.data.status === false) {
          this.disabled = false
          swal.fire({
            title: 'Erro!',
            text: 'Informações de Login estão incorretas!',
            type: 'error'
          })
        } else if (resp.data.status === 2) {
          this.disabled = false
          swal.fire({
            title: 'Erro!',
            text: 'Contacte o Administrador! Login suspenso!',
            type: 'error'
          })
        } else {
          this.disabled = false
          let erros = ''
          for (let erro of Object.values(resp.data)) {
            erros += erro + '<br>'
          }
          swal.fire({
            title: 'Erro!',
            html: erros,
            type: 'error'
          })
        }
      })
        .catch(() => {
          this.disabled = false
          swal.fire({
            title: 'Erro!',
            text: 'Tente novamente mais tarde!',
            type: 'error'
          })
        })
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
